var Handlebars = require('handlebars/runtime');

Handlebars.registerHelper('root', function () {
    return QelpCareCSR.router.root;
});
Handlebars.registerHelper('route', function ($route) {
    return QelpCareCSR.settings.routes[$route];
});
Handlebars.registerHelper('translate', function (path_str) {
    try {
        let locale = QelpCareCSR.settings.localization[QelpCareCSR.settings.locale];
        let path = path_str.split('/').map(key => `['${key}']`).join('');
        let result = locale;

        // Access each level of the object dynamically
        path.replace(/\[['"]([^'"]+)['"]\]/g, (match, key) => {
            result = result[key];
            return match; // Required by the replace function
        });

        return result !== undefined ? result : path_str;
    } catch (error) {
        console.error('Error in translation:', error);
        return path_str;
    }
});

Handlebars.registerHelper('concat', function (str1, str2) {
    return String(str1 + str2);
});
Handlebars.registerHelper('pluralize', function (number, path_str) {
    try {
        let locale = QelpCareCSR.settings.localization[QelpCareCSR.settings.locale];
        let keys = path_str.split('/');
        let options = keys.reduce((obj, key) => obj && obj[key], locale);

        if (options) {
            if (number === 0) {
                return options['none'];
            } else if (number === 1) {
                return options['single'];
            } else {
                return options['plural'];
            }
        } else {
            console.warn('Options not found for path:', path_str);
            return path_str;
        }
    } catch (error) {
        console.error('Error in pluralize helper:', error);
        return path_str;
    }
});

Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
    return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
});
Handlebars.registerHelper('resizeImage', function (url, size) {
    if (!url) return;
    let params = url.split("/");
    let filename = params.pop();
    params.push(size)
    params.push(filename);
    return params.join("/");
});
Handlebars.registerHelper("math", function (lvalue, operator, rvalue) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);
    return {
        "+": lvalue + rvalue,
        "-": lvalue - rvalue,
        "*": lvalue * rvalue,
        "/": lvalue / rvalue,
        "%": lvalue % rvalue
    }[operator];
});
