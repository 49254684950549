import { Dropdown } from "../../../../qelpcare-call-center-application/src/widgets/Dropdown";
import { Widgets } from "../../../../qelpcare-call-center-application/src/widgets/Widgets";

export class TrailList extends Widgets {
  constructor(element) {
    super(null);
    this.element = element;
  }
  init() {
    this.element.querySelectorAll(".dropdown").forEach((item, key) => {
      const name = key + Math.random();
      this.parent.widgets.register("dropdown" + name, new Dropdown(item));
    });
    this.content = this.element.querySelectorAll(".content");
    this.content.forEach((elem) => {
      const thumbnail = elem.querySelector(".thumbnail");
      if (thumbnail) {
        thumbnail.addEventListener("click", (e) => {
          this.openContent(e);
        });
      }
    });
  }
  // openContent(e) {
  //   const target = e.currentTarget;
  //   if (target.dataset.type == "youtube") {
  //     this.player = document.createElement("div");
  //     this.player.classList.add("player-wrapper");
  //     this.player.innerHTML = `<iframe width="314" height="180" src="${target.dataset.url}?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

  //     this.modal = document.createElement("div");
  //     this.modal.classList.add("ehelp-widget-modal", "youtube-player-modal");

  //     const close_btn = document.createElement("div");
  //     close_btn.classList.add("close-btn");
  //     close_btn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>';

  //     this.modal.append(close_btn);

  //     const open_attach = document.createElement("div");
  //     open_attach.classList.add("open_attach");
  //     open_attach.innerHTML = 'Material de apoio';

  //     const arquivos = document.createElement("div");
  //     arquivos.classList.add("file-container");
  //     arquivos.innerHTML = '<div></div> ';

  //     const fileElement = document.createElement("div");

  //     const anexo = target.dataset.anexo;
  //     const video_id = target.dataset.video_id;
  //     if(anexo === "1"){
  //       this.modal.append(open_attach);
  //       this.modal.append(arquivos);
  //       this.modal.append(fileElement);
  //       this.downloadAttachment(fileElement);
  //     }

  //     this.modal.append(this.player);

  //     close_btn.addEventListener("click", (e) => {
  //       this.modal.remove();
  //     });
  //     document.body.append(this.modal);
  //   }
  // }
  openContent(e) {
    const target = e.currentTarget;
    if (target.dataset.type == "youtube") {
      this.player = document.createElement("div");
      this.player.classList.add("player-wrapper");
      this.player.innerHTML = `<iframe width="314" height="180" src="${target.dataset.url}?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

      this.modal = document.createElement("div");
      this.modal.classList.add("ehelp-widget-modal", "youtube-player-modal");

      const close_btn = document.createElement("div");
      close_btn.classList.add("close-btn");
      close_btn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>';

      this.modal.append(close_btn);

      const open_attach = document.createElement("div");
      open_attach.classList.add("open_attach");
      open_attach.innerHTML = 'Material de apoio';

      const file_container = document.createElement("div");
      file_container.classList.add("file_container"); // Corrigido aqui

      file_container.append(open_attach);

      if (target.dataset.anexo === "1") {
        //this.modal.append(open_attach);
        this.modal.append(file_container);
        this.downloadAttachment(file_container, target.dataset.video_id);
      }

      this.modal.append(this.player);

      close_btn.addEventListener("click", (e) => {
        this.modal.remove();
      });
      document.body.append(this.modal);
    }
  }

  downloadAttachment(container, videoId) {
    const files = [
      {
        videoid: "2CuDAQ_O5do",
        name: "4.1 - Material de Apoio - Cálculo do Potencial de Vendas",
        path: "http://extranet.cs-sitel.com.br/company/1.xlsx"
      }
    ];

    files.filter(file => file.videoid === videoId).forEach(file => {
      const fileElement = document.createElement("div");
      fileElement.classList.add("files"); // Corrigido aqui
      const viewLink = document.createElement("a");
      viewLink.href = file.path;
      viewLink.textContent = `Ver ${file.name}`;
      fileElement.appendChild(viewLink);
      container.appendChild(fileElement);
    });
  }
}
